import React, { useState } from 'react';
import { MdClear } from "react-icons/md"; 
import { SiGoogletranslate } from "react-icons/si"; 
import { PulseLoader } from "react-spinners";
import stringSimilarity from 'string-similarity';
import { translate } from '@vitalets/google-translate-api'; // Importing translate function from Google Translate API


const SearchTool = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [googleResults, setGoogleResults] = useState([]);
    const [youtubeResults, setYoutubeResults] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [matchingPercentages, setMatchingPercentages] = useState({});
    const [remarks, setRemarks] = useState({});
    const [matchingyPercentages, setMatchingyPercentages] = useState({});

    const handleSearch = () => {
        const query = encodeURIComponent(`${title} ${description}`).replace(/%20/g, '+');
        if (query === '') {
            alert('Please enter project title and description.');
            return;
        }

        setLoading(true);

        setGoogleResults([]);
        setYoutubeResults([]);
        setSearchPerformed(true);

         // Use cors-anywhere proxy to bypass CORS restrictions
    const corsAnywhereProxyUrl = "https://www.helloindiaproconver.infintechstudio.site/";
    fetch(
      corsAnywhereProxyUrl + `https://www.helloindiaproconver.infintechstudio.site/server.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://www.helloindiaproconver.infintechstudio.site",
        },
        body: JSON.stringify({ links: selectedItems.map((item) => item.link) }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      })
      .catch((error) => {
        console.error("Error saving links:", error);
      });


        fetch(`https://www.googleapis.com/customsearch/v1?key=AIzaSyASJU-ALk6ffBw7BsPNJaY9Pmv9hS-e5Ek&cx=d1d61ed55479845f5&q&q=${query}&num=5`)
            .then(response => response.json())
            .then(data => {
                setGoogleResults(data.items || []);

                const matchingPercentages = {};
                data.items.forEach((item, index) => {
                    const similarity = stringSimilarity.compareTwoStrings(query, item.title + item.snippet);
                    matchingPercentages[`google${index}`] = similarity / 5;
                });
                setMatchingPercentages(matchingPercentages);
            })
            .catch(error => console.error('Error fetching Google search data:', error));

        fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyASJU-ALk6ffBw7BsPNJaY9Pmv9hS-e5Ek&q=${query}&part=snippet&type=video&maxResults=5`)
            .then(response => response.json())
            .then(data => {
                setYoutubeResults(data.items || []);

                const matchingyPercentages = {};
                data.items.forEach((item, index) => {
                    const similarity = stringSimilarity.compareTwoStrings(query, item.snippet.title);
                    matchingyPercentages[`youtube${index}`] = similarity / 5;
                });
                setMatchingyPercentages(matchingyPercentages);
            })
            .catch(error => console.error('Error fetching YouTube data:', error))
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClear = () => {
        setTitle('');
        setDescription('');
        setGoogleResults([]);
        setYoutubeResults([]);
        setSearchPerformed(false);
    };

    const handleCheckboxChange = (event, item) => {
        if (event.target.checked) {
            setSelectedItems(prevItems => [...prevItems, item]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(selectedItem => selectedItem !== item));
        }
    };

    const handleSave = () => {
        const selectedGoogleLinks = googleResults
            .filter((item) => selectedItems.includes(item))
            .map((item) => ({
                link: item.link,
                matchingPercentage: (matchingPercentages[`google${googleResults.indexOf(item)}`] || 0) * 100,
                remarks: remarks[item.link] || ''
            }));
    
        const selectedYoutubeLinks = youtubeResults
            .filter((item) => selectedItems.includes(item))
            .map((item) => ({
                link: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                matchingyPercentage: (matchingyPercentages[`youtube${youtubeResults.indexOf(item)}`] || 0) * 100,
                remarks: remarks[item.link] || ''
            }));
    
        // Combine selected links from both sources
        const selectedLinks = [...selectedGoogleLinks, ...selectedYoutubeLinks];
    
        fetch("http://www.helloindiaproconver.infintechstudio.site/server.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://www.helloindiaproconver.infintechstudio.site",
            },
            body: JSON.stringify({ 
                links: selectedLinks 
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data.message);
            alert(data.message);
        })
        .catch((error) => {
            console.error("Error saving links:", error);
            alert("Error saving links:", error.message);
        });
    };
    
    
    const handleTranslate = async () => {
        try {
            const translation = await translate(title, { from: 'ta', to: 'en' });
            setTitle(translation.text);
        } catch (error) {
            console.error('Error translating:', error);
        }
    };


    const handleRemarkChange = (event, link) => {
        const { value } = event.target;
        setRemarks(prevRemarks => ({
            ...prevRemarks,
            [link]: value
        }));
    };

    return (
        <div className="container">
            <div className="row">
                <div className="left-section col-sm-12 col-lg-6 col-md-6" style={{ position: 'sticky', top: 0, maxHeight: '100vh', overflowY: 'auto' }}>
                    <h2>Project Verification Tool </h2> 
                    <div className="search-bar">
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter project title..." style={{ fontSize: '14px' }}  />
                        <SiGoogletranslate className="google-translate-icon" onClick={handleTranslate} />
                        {title && <MdClear className="clear-icon" onClick={() => setTitle('')} /> }
                    </div>
                    <div className="search-bar">
                        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter project description..." style={{ fontSize: '14px' }} />
                        {description && <MdClear className="clear-icon" onClick={() => setDescription('')} />}
                    </div>
                    <button onClick={handleSearch}>Search</button>
                    {selectedItems.length > 0 && <button className='savebtn' onClick={handleSave}>Save</button>}
                   
                </div>
                <div className="right-section col-sm-12 col-lg-6 col-md-6" style={{ flex: 1, maxHeight: '100vh', overflowY: 'auto', }}>
                    <div className="container">
                        <div className="row">
                            {loading ? (
                                <div className="spinner-container">
                                    <PulseLoader color="#36d7b7" size={14} cssOverride={{ display: 'block', 'margin-left': '240px', 'margin-right': 'auto' }} loading={loading} />
                                </div>
                            ) : searchPerformed && googleResults.length === 0 && youtubeResults.length === 0 ? (
                                <p className="errormsg">Sorry buddy This Project not found</p>
                            ) : (
                                <>
                                    <div className="col-lg-6 col-sm-12">
                                        <h4>Google Results</h4>
                                        <div className="search-section">
                                            <div id="googleResults" className="search-results">
                                                {googleResults.map((item, index) => (
                                                    <div key={index} className="result-item">
                                                        <div className="result-content">
                                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                                <h3>{item.title}</h3>
                                                                <p>{item.snippet}</p>
                                                            </a>
                                                            <div className='pbar' style={{ boxShadow: selectedItems.includes(item) ? 'rgba(50, 255, 54, 0.2) 0px 8px 24px' : 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                                <p>Checked <input type="checkbox" className="result-checkbox" onChange={(event) => handleCheckboxChange(event, item)} /></p>
                                                                <p>Matching Percentage 
                                                                    <input 
                                                                        type="number"
                                                                        value={(matchingPercentages[`google${index}`] * 100).toFixed(0)}
                                                                        onChange={(e) => {
                                                                            const newPercentage = parseFloat(e.target.value) / 100;
                                                                            const updatedMatchingPercentages = { ...matchingPercentages };
                                                                            updatedMatchingPercentages[`google${index}`] = newPercentage;
                                                                            setMatchingPercentages(updatedMatchingPercentages);
                                                                        }}
                                                                        style={{ width: '40px' }}
                                                                    />%
                                                                </p>
                                                                <p className='rem'>Remarks 
                                                                    <input 
                                                                        type="text"
                                                                        value={remarks[item.link] || ''}
                                                                        onChange={(event) => handleRemarkChange(event, item.link)}
                                                                        placeholder="Add remarks..."
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <h4>Youtube Results</h4>
                                        <div className="search-section">
                                            <div id="youtubeResults" className="search-results">
                                                {youtubeResults.map((item, index) => (
                                                    <div key={index}>
                                                        <a href={`https://www.youtube.com/watch?v=${item.id.videoId}`} target="_blank" rel="noopener noreferrer">
                                                            <img src={item.snippet.thumbnails.default.url} alt={item.snippet.title} />
                                                        </a>
                                                        <div className='pbar' style={{ boxShadow: selectedItems.includes(item) ? 'rgba(50, 255, 54, 0.2) 0px 8px 24px' : 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                            <p className='che'>Checked  <input type="checkbox" onChange={(event) => handleCheckboxChange(event, item)} /></p>
                                                            <p>Matching Percentage
                                                                <input 
                                                                    type="number"
                                                                    value={(matchingyPercentages[`youtube${index}`] * 100).toFixed(0)}
                                                                    onChange={(e) => {
                                                                        const newPercentage = parseFloat(e.target.value) / 100;
                                                                        const updatedMatchingPercentages = { ...matchingyPercentages };
                                                                        updatedMatchingPercentages[`youtube${index}`] = newPercentage;
                                                                        setMatchingyPercentages(updatedMatchingPercentages);
                                                                    }}
                                                                    style={{ width: '40px' }}
                                                                />%
                                                            </p>
                                                            <p className='rem'>Remarks 
                                                                <input 
                                                                    type="text"
                                                                    value={remarks[item.link] || ''}
                                                                    onChange={(event) => handleRemarkChange(event, item.link)}
                                                                    placeholder="Add remarks..."
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
           
        </div>
        
    );
};

export default SearchTool;
