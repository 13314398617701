import React, { useState } from 'react';
import './App.css';
import SearchTool from './SearchTool';
import { TbReport } from "react-icons/tb";
import { FaFireFlameCurved } from "react-icons/fa6";
import Report from './Report'; // Import the Report component

function App() {
  const [showReport, setShowReport] = useState(false); // State to manage whether to show Report

  const handleLoadPlagiarismDetector = () => {
    window.open("https://plagiarismdetector.net/", "_blank");
  };

  const handleLoadReport = () => {
    setShowReport(true); // Set showReport state to true to render the Report component
  };

  return (
    <div className="App">
      <div className='par1'>
        { !showReport && <SearchTool/> } {/* Render SearchTool only if showReport is false */}
      </div>
      { showReport ? ( // Conditional rendering based on showReport state
        <Report />
      ) : (
        <div className="par">
          <div className="fire-icon" onClick={handleLoadPlagiarismDetector}>
            <FaFireFlameCurved className='icon1' />
          </div>
          <div className="fire-icon" onClick={handleLoadReport}>
            <TbReport className='icon1' />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
