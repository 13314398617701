import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // Import the left arrow icon from react-icons/fa

const Report = () => {
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("http://www.helloindiaproconver.infintechstudio.site/server.php")
      .then(response => response.json())
      .then(data => {
        setProjectData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const handleBack = () => {
    window.location.href = '/'; // Navigate to the root URL
  };

  return (
    <div className='tkk'>
      <h5><div className="back-arrow" onClick={handleBack}>
        <FaArrowLeft /> {/* Left arrow icon */}
      </div> Project Report</h5>
    
      <div className='thk'>
      <table className="modern-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Link</th>
            <th>Percent</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {projectData.map((project, index) => (
            <tr key={index}>
              <td>{project.id}</td>
              <td>{project.link}</td>
              <td>{project.percent}</td>
              <td>{project.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
     
    </div>
  );
};

export default Report;
